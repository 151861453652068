const aliases = {
    rightlink1: {
        page: true,
        name: 'app.root.layer1.layer2.catalog.live',
        params: {
            name: 'top-live-casino',
        },
    },
    rightlink2: {
        page: true,
        name: 'app.root.layer1.layer2.catalog.category',
        params: {
            name: 'slots'
        }
    },
    rightlink3: {
        page: true,
        name: 'app.root.layer1.layer2.catalog.category',
        params: {
            name: 'popular'
        }
    },
    rightlink4: {
        page: true,
        name: 'app.root.layer1.layer2.catalog.category',
        params: {
            name: 'top'
        },
    },

    casinoTop: {
        page: true,
        name: 'app.root.layer1.layer2.catalog.category',
        params : {
            name: 'top'
        }
    },

    topCategory: {
        page: true,
        name: "app.root.layer1.layer2.catalog.category",
        params : {
            name: 'top'
        }
    },

    leftlink1: {
        page: true,
        name: 'app.root.layer1.layer2.sporttournament',
    },
    leftlink2: {
        page: true,
        name: 'app.root.layer1.league',
    },
    leftlink3: {
        page: true,
        name: 'app.root.layer1.layer2.mask-collection.list',
    },

    home: {
        page: true,
        name: 'app.root.layer1.home',
    },
    promo: {
        page: true,
        name: 'app.root.layer1.layer2.promos.list',
    },
    promos: {
        page: true,
        name: 'app.root.layer1.layer2.promopage'
    },
    login: {
        page: false,
        name: 'login',
    },
    registration: {
        page: false,
        name: 'registration',
    },
    paynplay: {
        page: true,
        name: 'app.root.layer1.registration-bank',
    },
    deposit: {
        page: false,
        name: 'cashbox',
        private: true
    },
    withdrawal: {
        page: false,
        name: 'cashbox',
        private: true,
        content: {
            action: 'withdrawal',
        },
    },
    withdraw: {
        page: false,
        name: 'cashbox',
        private: true,
        content: {
          action: 'withdrawal',
        },
    },
    history: {
        page: false,
        name: 'cashbox',
        content: { 'tab' : 'history'  },
        private: true
    },
    realGame: {
        page: true,
        name: 'app.root.game.real',
    },
    shop: {
        page: true,
        name: 'app.root.layer1.shop.main',
    },
    achievements: {
        page: true,
        name: 'app.root.layer1.layer2.achievements',
    },
    tournaments: {
        page: true,
        name: 'app.root.layer1.layer2.tournament.list',
    },
    profile: {
        page: true,
        name: 'app.root.layer1.layer2.account.heroroom',
    },
    bonuses: {
        page: true,
        name: 'app.root.layer1.layer2.account.bonus',
        private: true,
    },
    availableBonuses: {
        page: true,
        name: 'app.root.layer1.layer2.account.bonus',
        private: true,
    },
    notFound: {
        page: true,
        name: 'app.root.layer1.layer2.not-found',
    },
    missedData: {
        page: true,
        name: 'app.root.missed-data',
    },
    chat: {
        page: false,
        name: 'chat',
    },
    sportEvent: {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
    },
    sport: {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
    },
    bonus_crab: {
        page: true,
        name: 'app.root.claw_games.gamepage',
        params: {
            name: 'cash-crab'
        }
    },
    cash_crab: {
        page: true,
        name: 'app.root.claw_games.gamepage',
        params: {
            name: 'cash_crab'
        }
    },
    claw: {
        page:true,
        name: 'app.root.claw_games.gamepage'
    },
    terms: {
        page: true,
        name: 'app.root.layer1.layer2.rules'
    },
    pragmatic: {
        page: true,
        name: "app.root.layer1.layer2.catalog.provider.hall",
        params: {
            name: 'pragmatic'
        }
    },
    threeoaks: {
        page: true,
        name: "app.root.layer1.layer2.catalog.provider.hall",
        params: {
            name: 'threeoaks'
        }
    },
    spinomenal: {
        page: true,
        name: "app.root.layer1.layer2.catalog.provider.hall",
        params: {
            name: 'spinomenal'
        }
    },
    ela: {
        page: true,
        name: "app.root.layer1.layer2.catalog.provider.hall",
        params: {
            name: 'ela'
        }
    },
    playngo: {
        page: true,
        name: "app.root.layer1.layer2.catalog.provider.hall",
        params: {
            name: 'playngo'
        }
    },
    relax: {
        page: true,
        name: "app.root.layer1.layer2.catalog.provider.hall",
        params: {
            name: 'relax'
        }
    },
    skywind: {
        page: true,
        name: "app.root.layer1.layer2.catalog.provider.hall",
        params: {
            name: 'skywind'
        }
    },
    netent: {
        page: true,
        name: "app.root.layer1.layer2.catalog.provider.hall",
        params: {
            name: 'netent'
        }
    },
    pushgaming: {
        page: true,
        name: "app.root.layer1.layer2.catalog.provider.hall",
        params: {
            name: 'pushgaming'
        }
    },
    playtech: {
        page: true,
        name: "app.root.layer1.layer2.catalog.provider.hall",
        params: {
            name: 'playtech'
        }
    },
    quickspin: {
        page: true,
        name: "app.root.layer1.layer2.catalog.provider.hall",
        params: {
            name: 'quickspin'
        }
    },
    redtiger: {
        page: true,
        name: "app.root.layer1.layer2.catalog.provider.hall",
        params: {
            name: 'redtiger'
        }
    },
    evolution: {
        page: true,
        name: "app.root.layer1.layer2.catalog.provider.hall",
        params: {
            name: 'evolution'
        }
    },
    halloween: {
        page: true,
        name: "app.root.layer1.layer2.tournament.page",
        params: {
            name: 'halloween'
        }
    },
    christmasTournament: {
        page: true,
        name: "app.root.layer1.layer2.tournament.page",
        params: {
            name: 'party-raffle'
        }
    },
    euro2024: { 
        page: true,
        name: "app.root.layer1.layer2.tournament.page",
        params: {
            name: 'euro2024'
        }
    },
    euroFootball: {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
        params: {
            champids: '3031',
        },
    },
    promotions: {
        page: true,
        name: "app.root.layer1.layer2.promos.root"
    },
    soccer: {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
        params: {
          sportids: '66',
        },
    },

    summer: {
        page: true,
        name: 'app.root.summer',
    },
    summerGame: {
        page: true,
        name: 'app.root.summer-game',
    },
    bet: {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
    },

    oktoberfest: {
        page: true,
        name: 'app.root.claw_games.gamepage',
        params: {
            name: 'oktoberfest',
        },
    },

    games: {
        page: true,
        name: 'app.root.layer1.layer2.catalog.category',
        params: {
            name: 'top'
        },
    },

    live: {
        page: true,
        name: 'app.root.layer1.layer2.catalog.live',
        params: {
            name: 'top-live-casino',
        },
    },

    HoRacing: {
        page: true,
        name: 'app.root.layer1.horse_racing.horse_racing',
    },

    HaRacing: {
        page: true,
        name: 'app.root.layer1.horse_racing.harness',
    },

    GreyRacing: {
        page: true,
        name: 'app.root.layer1.horse_racing.greyhound',
    },

    NextToJump: {
        page: true,
        name: 'app.root.layer1.horse_racing.next-to-jump',
    },

    //- Additional aliases for Promo Bonus Steps (RND-3482)
    football: {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
        params: {
            sportids: '66',
        },
    },
    tenis: {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
        params: {
            sportids: '68',
        },
    },
    tennis: {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
        params: {
            sportids: '68',
        },
    },
    tabletenis: {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
        params: {
            sportids: '77',
        },
    },
    'table-tennis': {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
        params: {
            sportids: '77',
        },
    },
    basketball: {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
        params: {
            sportids: '67',
        },
    },
    icehockey: {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
        params: {
            sportids: '70',
        },
    },
    'ice-hockey': {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
        params: {
            sportids: '70',
        },
    },
    esports: {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
        params: {
            sportids: '145',
        },
    },
    formula1: {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
        params: {
            sportids: '103',
        },
    },
    mma: {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
        params: {
            sportids: '84',
        },
    },
    cricket: {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
        params: {
            sportids: '74',
        },
    },
    americanfootball: {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
        params: {
            sportids: '75',
        },
    },
    'american-football': {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
        params: {
            sportids: '75',
        },
    },
    baseball: {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
        params: {
            sportids: '76',
        },
    },
    efootball: {
        page: true,
        name: 'app.root.layer1.sportpage.prelive',
        params: {
            sportids: '146',
        },
    },
    virtual: {
        page: true,
        name: 'app.root.layer1.sportpage.virtual',
    },
    virtualSport: {
        page: true,
        name: 'app.root.layer1.sportpage.virtual',
    },
    dropwins: {
        page: true,
        name: "app.root.layer1.layer2.tournament.page",
        params: {
            name: 'drops-wins'
    }
},
};
export { aliases };
